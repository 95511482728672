<template>
	<div class="use-gas-analysis">
		<div class="p-title">
			<h3>用气分析</h3>
		</div>
		<div class="content">
			<div class="device-list" v-loading="loading">
				<div class="p-title">
					<h3>设备列表</h3>
				</div>
				<div class="list">
					<div
						class="list-item"
						:class="{ selected: iotDeviceIds.includes(item.iotDeviceId) }"
						v-for="(item, index) in relationDeviceList"
						:key="index"
						@click="handleClickDevice(item.iotDeviceId)"
					>
						<div class="title_n">
							<img src="@/assets/imgs/icon/rsq.svg" alt="" class="icon" />
							<p class="name">{{ item.deviceTypeName }}</p>
							<em class="tag" :class="{ offline: item.isOnline !== 1 }">
								{{ item.isOnline === 1 ? '在线' : '离线' }}
							</em>
						</div>
						<div class="label-form">
							<div class="label-form-item">
								<div class="label-form-item__title">编号：</div>
								<div class="label-form-item__value">{{ item.deviceNo }}</div>
							</div>
							<div class="label-form-item" v-if="item.gasDeviceNo">
								<div class="label-form-item__title">条形编码：</div>
								<div class="label-form-item__value">{{ item.gasDeviceNo }}</div>
							</div>
							<div class="label-form-item">
								<div class="label-form-item__title">设备地址：</div>
								<div class="label-form-item__value">
									{{ item.addressName }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="analysis-result">
				<div class="top-search">
					<gc-custom-search
						keyWord="分析时间"
						width="360px"
						type="date"
						datePickerType="date"
						:search.sync="form.analysisTime"
						:clearable="false"
						valueFormat="yyyy-MM-dd"
					></gc-custom-search>
					<div class="serach-ops">
						<el-button type="primary" v-click-blur @click="findAnalyzeUseGas">分 析</el-button>
					</div>
				</div>
				<div class="analysis-chart" v-loading="renderLoading">
					<div id="analysis"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetDeviceListFromCustomer, apiGetAnalyzeUseGas } from '@/apis/customerManage.api.js'
const colors = ['#1ED974', '#567CED', '#FFAC05', '#ED5672', '#F4753F']
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/legend')
export default {
	name: 'useGasAnalysis',
	data() {
		return {
			chartInstance: null,
			form: {
				analysisTime: this.dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
			},
			userNo: this.$route.query.userNo,
			relationDeviceList: [],
			iotDeviceIds: [],
			useGasDataList: [],
			loading: false,
			renderLoading: false,
		}
	},
	created() {
		this.findDeviceListFromCutomer()
	},

	mounted() {
		const analysisDoc = document.querySelector('#analysis')
		this.chartInstance = echarts.init(analysisDoc)
	},

	methods: {
		async findDeviceListFromCutomer() {
			this.loading = true
			try {
				const data = await apiGetDeviceListFromCustomer({
					userNo: this.userNo,
				})
				this.relationDeviceList = data
				this.iotDeviceIds = this.relationDeviceList.map(o => o.iotDeviceId)
				this.findAnalyzeUseGas()
			} finally {
				this.loading = false
			}
		},

		async findAnalyzeUseGas() {
			if (!this.iotDeviceIds.length) {
				return this.$message.warning('请至少选择一台设备进行用气分析')
			}
			this.renderLoading = true
			try {
				const data = await Promise.all(
					this.iotDeviceIds.map(iotDeviceId => {
						const { analysisTime } = this.form
						return apiGetAnalyzeUseGas({
							iotDeviceId,
							startTime: `${analysisTime} 00:00:00`,
							endTime: `${analysisTime} 23:59:59`,
						})
					}),
				)
				this.useGasDataList = data
				this.chartInstance.setOption(this.packageChartOptions())
			} finally {
				this.renderLoading = false
			}
		},

		handleClickDevice(iotDeviceId) {
			const idx = this.iotDeviceIds.findIndex(o => o === iotDeviceId)
			if (idx > -1) {
				this.iotDeviceIds.splice(idx, 1)
			} else {
				this.iotDeviceIds.push(iotDeviceId)
			}
		},

		packageChartOptions() {
			const { analysisTime } = this.form
			const minT = new Date(this.dayjs(analysisTime).format('YYYY-MM-DD') + ' 00:00:00').getTime()
			const maxT = new Date(this.dayjs(analysisTime).format('YYYY-MM-DD') + ' 23:59:59').getTime()
			const series = new Array(24).fill(null).map((o, index) => {
				return {
					name: `Gantt${index}`,
					type: 'bar',
					stack: 'Total',
					barWidth: 18,
					data: new Array(this.useGasDataList.length).fill(null),
				}
			})
			for (let i = 0; i < series.length; i++) {
				for (let k = 0; k < this.useGasDataList.length; k++) {
					const useGas = this.useGasDataList[k].detail[i]?.useGas
					const endV = this.useGasDataList[k].detail[i]?.endTime
					const startV = this.useGasDataList[k].detail[i]?.startTime
					const v = new Date(endV).getTime() - new Date(startV).getTime() || 0
					series[i].data[k] = {
						value: v + (i === 0 ? minT : 0),
						timeRange: `${startV} ~ ${endV}`,
						useGas,
						itemStyle: {
							color: Number(useGas) > 0 || useGas === 'hasUse' ? colors[k % 5] : 'transparent',
							borderColor: Number(useGas) > 0 || useGas === 'hasUse' ? colors[k % 5] : 'transparent',
							borderRadius: 1,
						},
					}
				}
			}
			const options = {
				tooltip: {
					trigger: 'item',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function (params) {
						if (params.data.itemStyle.borderColor === 'transparent') return
						const {
							name,
							data: { timeRange, useGas },
						} = params
						return `${name}
              <p style="font-size: 12px; margin-top: 4px; color: #999">用气时间：<span style="color: #000">${timeRange}</span></p>
              <p style="font-size: 12px; margin-top: 4px; color: #9A9CB0; display: ${
					useGas === 'hasUse' ? 'none' : 'block'
				}">本次用气量：<span style="color: #000">${useGas}m³</span></p>`
					},
				},
				grid: {
					left: 20,
					right: 30,
					bottom: 30,
					top: 30,
					containLabel: true,
				},
				xAxis: {
					type: 'value',
					min: minT,
					max: maxT,
					interval: 60 * 60 * 1000 * 1.5,
					axisLabel: {
						formatter: value => {
							return this.dayjs(value).format('HH:mm:ss')
						},
						color: '#000',
					},
					splitLine: {
						lineStyle: {
							color: '#f1f1f180',
						},
					},
				},
				yAxis: {
					type: 'category',
					data: this.useGasDataList.map(o => o.deviceName),
					axisLabel: {
						color: '#000',
						fontSize: 12,
					},
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
				},
				series,
			}
			return options
		},
	},
}
</script>
<style lang="scss" scoped>
.use-gas-analysis {
	height: 100%;
	padding: 12px 24px;
	display: flex;
	flex-direction: column;
	.p-title {
		line-height: 48px;
		h3 {
			position: relative;
			padding-left: 12px;
			color: #3f435e;
			font-size: 18px;
			font-weight: 600;
			&:after {
				content: '';
				width: 4px;
				height: 16px;
				background-color: #4a69ff;
				position: absolute;
				left: 0;
				top: 17px;
			}
		}
	}
	.label-form {
		padding: 0 4px;
		&-item {
			display: flex;
			padding: 4px 0;
			&__title {
				color: #9a9cb0;
			}
			&__value {
				color: #5f627d;
				width: 0;
				flex: 1;
				text-align: right;
				line-height: 1.2;
			}
		}
	}
	.content {
		flex: 1;
		height: 0;
		display: flex;
		margin-top: 12px;
		.device-list {
			width: 18.75vw;
			height: 100%;
			padding: 12px;
			border: 1px solid #f1f1f1;
			border-radius: 4px;
			overflow-y: scroll;
			.p-title {
				h3 {
					font-size: 17px;
				}
			}
			.list {
				margin-top: 12px;
				&-item {
					background-color: #fbfcff;
					border-radius: 6px;
					border: 1px solid #f1f1f1;
					padding: 12px;
					position: relative;
					cursor: pointer;
					&.selected {
						background-color: #e8efff;
						border-color: #567ced;
						overflow: hidden;
						&:after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-top: 12px solid #4f7aff;
							border-right: 12px solid #4f7aff;
							border-bottom: 12px solid transparent;
							border-left: 12px solid transparent;
							z-index: 9;
							right: 0;
							top: 0;
						}
						&:before {
							content: '';
							position: absolute;
							background-color: #fff;
							width: 16px;
							height: 16px;
							top: 0;
							right: 0;
							font-size: 12px;
							z-index: 10;
							clip-path: polygon(44% 55%, 79% 18%, 87% 24%, 44% 70%, 16% 38%, 23% 31%);
						}
					}
					&.can-click {
						cursor: pointer;
					}
					&:not(:last-child) {
						margin-bottom: 16px;
					}
					.title_n {
						display: flex;
						align-items: center;
						padding-right: 12px;
						.name {
							color: #4e4e4e;
							font-weight: 500;
							font-size: 16px;
							margin-left: 8px;
						}
						.tag {
							margin-left: auto;
							background-color: #16c972;
							color: #fff;
							font-size: 12px;
							border-radius: 12px;
							padding: 3px 8px 4px 8px;
							&.offline {
								background-color: #aaabbd;
							}
						}
					}
					.label-form {
						margin-top: 12px;
					}
				}
			}
		}
		.analysis-result {
			width: 0;
			flex: 1;
			margin-left: 12px;
			display: flex;
			flex-direction: column;
			.top-search {
				display: flex;
				.serach-ops {
					margin-left: 12px;
					@include base-button;
				}
			}
			.analysis-chart {
				height: 0;
				flex: 1;
				#analysis {
					height: 100%;
				}
			}
		}
	}
}
</style>
